import React, { useState, useEffect }  from 'react'
// import ExportExcel from 'react-export-excel';
import {makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import { 
    DatePicker,
    Select,
    Typography,
    Tag,
    Button,
    message
} from 'antd';
import {
    SyncOutlined,
} from '@ant-design/icons';
const { Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const UrlCombos = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/get_methods/aprobacion/getComboValidacion.php?token=015ec0d618fe3fa9c7d0a1abda2b159c";
// const UrlDataValidada = "https://validacion.ventasvirtuales.com.ec/Ws/procedures/get_methods/aprobacion/getDataValidada.php?token=015ec0d618fe3fa9c7d0a1abda2b159c";
// const ExcelFile =ExportExcel.ExcelFile;
// const ExcelSheet =ExportExcel.ExcelSheet;
// const ExcelColumn =ExportExcel.ExcelColumn;

const PymentsPage = () => {
    const styles= useStyles();
    const [state, setSatate] = useState({
        Fecha : "",
        Validacion:"",
    })

    useEffect(()=>{
        Inicializar();
    },[])

    const [Validacion ,setValidacion] = useState([]);

    const Inicializar = async () =>{
        await axios.get(UrlCombos)
        .then(response=>{
            setValidacion(response.data.body)
        }).catch(error => {
            console.log(error)
            message.warning('Por favor, evite hacer peticiones sin sentido de lo contrario se bloqueara la información');
        });
    }
    const LISTAR_VALIDADA = Validacion.map((Validacion) =>   
        <Option key ={Validacion.key} value={Validacion.label} >{Validacion.label}</Option>
    );
    const handleChange = (e,name) =>{
        setSatate(estado=>({
            ...estado,
            [name]: e
        }))
    }
    const handleChangeFechas = (target,name) =>{
        setSatate(estado=>({
            ...estado,
            [name]: target
        }))
    }
    
    const Guardar = async () =>{
        message.success('Haga su consulta y estara listo para exportar');
        // console.log(state)
    }

    return (
        <div>
            
            <div className={styles.Contenedor}>
                <Tag  className={styles.Titulo} icon={<SyncOutlined spin />} color="processing">
                    Exportar Excel
                </Tag>
            </div>
            <div className={styles.Contenedor}>

                <div className={styles.InfoFechas}>
                    <Text  code>FECHAS</Text>
                    <RangePicker 
                        className={styles.Cuadros}
                        showTime 
                        name="Fecha"
                        onChange={
                            (moment,string)=>{
                                handleChangeFechas(string,"Fecha")
                            }
                        }
                    />
                </div>

                <div className={styles.Info}>
                    <Text  code>VALIDACION</Text>
                    <Select 
                        className={styles.Cuadros}
                        placeholder="SELECCIONE"
                        size={'default'}
                        name="Validacion"
                        onChange={(e)=>handleChange(e,'Validacion')}
                    >
                        { LISTAR_VALIDADA }
                    </Select>
                </div>

                <div className={styles.Info}>

                    <Button 
                        className={styles.Boton} 
                        type="primary"  
                        onClick={()=>Guardar()}
                    >Exportar
                    </Button> 
                </div>

            </div>
        </div>
    )
}

export default PymentsPage

const useStyles = makeStyles(() => ({

    Contenedor:{
        textAlign:'center',
    },
    InfoFechas:{
        padding:'12px',
        width:'35%',
        float:'left',
        "@media (max-width: 900px)": {
            width:'100%',
            textAlign:'left'
        }
    },
    Info:{
        padding:'12px',
        width:'14%',
        float:'left',
        "@media (max-width: 900px)": {
            width:'100%',
            textAlign:'left'
        }
    },
    Cuadros:{
        marginTop:'5px',
        width:'100%',
        textAlign:'center',
    },
    Titulo:{
        marginTop:'22px'
    },
    Boton:{
        width:'100%',
        marginTop:'25px'
    }

}));
