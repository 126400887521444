import React , {useState , useEffect}  from 'react'
import {makeStyles} from '@material-ui/core/styles';
// import Highlighter from 'react-highlight-words';
import axios from 'axios';
import { 
    Table , 
    Button , 
    Modal , 
    Input , 
    Form , 
    Select , 
    notification , 
    Popconfirm ,
    message 
} from 'antd';

import 'antd/dist/antd.css';
const pagination = {
    pageSize: 5,
}
const layout = {
    labelCol:{
        span:5
    },
    wrapperCol:{
        span:20
    }
}
const {Item} = Form;
const UrlGet = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/get_methods/usuario/getPermisosAll.php?Token=015ec0d618fe3fa9c7d0a1abda2b159c";
const UrlPost = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/post_methods/usuario/guardarPermiso.php";
const UrlUpdate = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/post_methods/usuario/actualizarPermiso.php";
const UrlDelete = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/post_methods/usuario/eliminarPermiso.php";
const UrlEstados = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/get_methods/usuario/getEstados.php?Token=015ec0d618fe3fa9c7d0a1abda2b159c";
const UrlRoles = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/get_methods/usuario/getRoles.php?Token=015ec0d618fe3fa9c7d0a1abda2b159c";

const UrlPadres = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/get_methods/usuario/getPadres.php?Token=015ec0d618fe3fa9c7d0a1abda2b159c";
const { Option } = Select;
const PermisosPage = () => {

    const styles= useStyles();

    const [data ,setData] = useState([]);
    const [loading ,setLoading] = useState(false);

    const [estados ,setEstados] = useState([]);
    const [roles ,setRoles] = useState([]);

    const [padres ,setPadres] = useState([]);

    const [RolSelect ,setRolSelect] = useState([]);

    const [disablePadre ,setDisablePadre] = useState(true);

    const Permisos = {
        token:'015ec0d618fe3fa9c7d0a1abda2b159c',
        permisoid:'',
        permisodescripcion:'',
        permisoruta:'',
        permisoicono:'',
        permisorol:'',
        permisopadre:'',
        permisoestado:'',
    }

    const [permisos, setPermiso] = useState(Permisos)
    const [LabelModal, setLabelModal] = useState("");
    const [modal, setModal] = useState(false);

    const openNotificationSuccess = (posicion,mensaje,descripcion) => {
        notification.success({
            message: mensaje,
            description: descripcion,
            posicion,
        });
    };

    const openNotificationError = (posicion,mensaje,descripcion) => {
        notification.error({
            message: mensaje,
            description: descripcion,
            posicion,
        });
    };

    useEffect(()=>{
        InicializarCombos();
    },[])

    useEffect(()=>{
        // GetUsuarios(1);
        // setLoading(true)
    },[])

    const InicializarCombos = async () =>{

        await axios.get(UrlRoles)
        .then(response=>{
            setRoles(response.data.body)
        })

        await axios.get(UrlEstados)
        .then(response=>{
            setEstados(response.data.body)
        })
    }

    const ObtenerPadre = async (Rol) =>{
        await axios.get(UrlPadres+'&Rol='+Rol)
        .then(response=>{
            setPadres(response.data.body)
        })   
    }

    const LISTAR_ROLES = roles.map((roles) =>   
                                    <Option key={roles.key} value={roles.rolid} >{roles.roldescripcion}</Option>
                                );

    const LISTAR_ESTADOS = estados.map((estados) =>   
                                    <Option key={estados.key} value={estados.estadodescripcion} >{estados.estadodescripcion}</Option>
                                );
    const LISTAR_PADRES = padres.map((padres) =>   
                                    <Option key={padres.key} value={padres.Id} >{padres.Descripcion}</Option>
                                );

    const GetUsuarios = async (Rol) =>{
        await axios.get(UrlGet+"&Rol="+Rol)
        .then(response=>{
                setData(response.data.body)
                setLoading(false)
        })
    }

    const AbrirModal=()=>{
        setModal(!modal);
    }

    const ControlModales = (data,name) =>{
        if(name==='Ingresar'){
            setDisablePadre(true)
        }
        if(name==='Editar'){
            setDisablePadre(false)
        }
        setPermiso(data)
        setLabelModal(name)
        AbrirModal();
    }

    const confirm = (dato) =>{
        EliminarPermiso(dato);
    }

    const EliminarPermiso = async (dato) =>{

        let Json = {
            token:'015ec0d618fe3fa9c7d0a1abda2b159c',
            id : dato.permisoid
        }
        await axios.post(UrlDelete , Json)
        .then(response=>{
            if(response.data.status === "OK"){
                openNotificationSuccess('topRight','Accion exitosa','El permisos ha sido eliminado correctamente.')
            }else{
                openNotificationError('topRight','Error','No se pudo eliminar el permisos!')
            }
            setLoading(true)
            GetUsuarios(RolSelect);
        })
    }

    const ControlOperaciones = (name) =>{

        const OPCION_OPERACIONES = {
            'Ingresar': () =>{ CrearPermiso() },
            'Editar':() =>{ EditarPermiso() },
        }
        OPCION_OPERACIONES[name]();
    }
    
    const CrearPermiso = async () =>{

        if(ValidacionPermiso()){

            let Padre = permisos.permisopadreId;
            // console.log(Padre)
            if( permisos.permisopadreId === undefined){
                Padre = 0;
            }

            let Json = {
                token:'015ec0d618fe3fa9c7d0a1abda2b159c',
                descripcion: permisos.permisodescripcion,
                ruta: permisos.permisoruta,
                icono: permisos.permisoicono,
                rol: permisos.permisorolId,
                padre: Padre,
                estado: permisos.permisoestado,
            }
            // console.log(Json);
            await axios.post(UrlPost , Json)
            .then(response=>{
                if(response.data.status === "OK"){
                    openNotificationSuccess('topRight','Accion exitosa','El permisos ha sido creado correctamente.')
                }else{
                    openNotificationError('topRight','Error',response.data.message)
                }
                setLoading(true)
                GetUsuarios(RolSelect);
                AbrirModal()
            })
        }
        
    }

    const EditarPermiso = async () =>{

        if(ValidacionPermiso()){
            let Json = {
                token:'015ec0d618fe3fa9c7d0a1abda2b159c',
                id: permisos.permisoid,
                descripcion: permisos.permisodescripcion,
                ruta: permisos.permisoruta,
                icono: permisos.permisoicono,
                rol: permisos.permisorolId,
                padre: permisos.permisopadreId,
                estado: permisos.permisoestado,
            }
            // console.log(Json)
            await axios.post(UrlUpdate , Json)
            .then(response=>{
                if(response.data.status === "OK"){
                    openNotificationSuccess('topRight','Accion exitosa','El permisos ha sido editado correctamente.')
                }else{
                    openNotificationError('topRight','Error','No se pudo editar el permisos!')
                }
                setLoading(true)
                GetUsuarios(RolSelect);
                AbrirModal()
            })
        }

    }

    const ValidacionPermiso = () =>{
        if( 
            ValidarPermiso() && 
            ValidarRuta () && 
            ValidarIcono() && 
            ValidarRol() && 
            ValidarEstado()
        ){
            return true;
        }
        return false;
    }

    const ValidarPermiso = () =>{
        if(permisos.permisodescripcion ==='' || permisos.permisodescripcion ===null){
            message.warning('Ingrese el nombre del permisos');
            return false;
        }
        return true;
    }
    const ValidarEstado = () =>{
        if(permisos.permisoestado ==='' || permisos.permisoestado ===null){
            message.warning('Ingrese el estado del permisos');
            return false;
        }
        return true;
    }

    const ValidarRuta = () =>{
        if(permisos.permisoruta ==='' || permisos.permisoruta ===null){
            message.warning('Ingrese la ruta del permisos');
            return false;
        }
        return true;
    }

    const ValidarIcono = () =>{
        if(permisos.permisoicono ==='' || permisos.permisoicono ===null){
            message.warning('Ingrese el icono del permisos');
            return false;
        }
        return true;
    }

    const ValidarRol = () =>{
        if(permisos.permisorolId ==='' || permisos.permisorolId ===null){
            message.warning('Ingrese el rol del permisos');
            return false;
        }
        return true;
    }

    const handleChange = (e) =>{
        const {name, value}= e.target;
        setPermiso(permisos=>({
            ...permisos,
            [name]: value
        }))
    }

    const ObtenerSelect = (Estado,Opcion) =>{
        // console.log(Opcion);
        // console.log(Estado);
        if(Opcion==='permisorolId'){
            setDisablePadre(false)
            ObtenerPadre(Estado);
        }
        setPermiso(permisos=>({
            ...permisos,
            [Opcion]: Estado
        }))
    }

    const OnChangeRol = (Rol) =>{
        setLoading(true)
        GetUsuarios(Rol);
        setRolSelect(Rol)
        ObtenerPadre(Rol);
    }   

    const columns = [
        {
            title:'Id',
            dataIndex:'permisoid',
            key:'permisoid',
            fixed: 'left',
            width: 100
        },
        {
            title:'Nombre',
            dataIndex:'permisodescripcion',
            key:'permisodescripcion',
            width: 200
        },
        {
            title:'Ruta',
            dataIndex:'permisoruta',
            key:'permisoruta',
            width: 200
        },
        {
            title:'Icono',
            dataIndex:'permisoicono',
            key:'permisoicono',
            width: 200
        },
        {
            title:'Rol',
            dataIndex:'permisorol',
            key:'permisorol',
            width: 200
        },
        {
            title:'Padre',
            dataIndex:'permisopadre',
            key:'permisopadre',
            width: 200
        },
        {
            title:'Estado',
            dataIndex:'permisoestado',
            key:'permisoestado',
            width: 200
        },
        {
            title:'Acciones',
            key:'acciones',
            fixed: 'right',
            render: fila=>(
                <>
                    <Button type="primary" onClick={()=>ControlModales(fila,'Editar')}>Editar</Button> {" "}
                    <Popconfirm
                        placement="topRight"
                        title={'Desea eliminara este permiso?'}
                        onConfirm={()=>confirm(fila)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" danger>Eliminar</Button>
                    </Popconfirm>
                    
                </>
            ),
            width: 200
        },
    ]

    return (
        <>
            <div className={styles.Imagen}>
                {/* {
                    JSON.stringify(data)
                } */}
                <Button className={styles.BtnIngresar} type="primary"  onClick={()=>ControlModales(Permisos,'Ingresar')}>Ingresar</Button>
                
                <Select 
                    className={styles.ComboRol}
                    onChange={(e)=>OnChangeRol(e)}
                    size="default" 
                    name="permisorol"
                    placeholder="Seleccione el Rol"
                >
                    { LISTAR_ROLES }
                </Select>
                <Table className={styles.table} loading={loading} columns={columns} key={data.key}  dataSource={data}  pagination={pagination}  scroll={{ y: 350 , x: 900 }} />
            </div>

            {/* {
                JSON.stringify(permisos)
            } */}

            <Modal
                visible={modal}
                title = {LabelModal + " Permiso"}
                destroyOnClose={true}
                onCancel={AbrirModal}
                centered
                footer={[
                    <Button onClick={AbrirModal}>Cancelar</Button>,
                    <Button type="primary" onClick={()=>ControlOperaciones(LabelModal)}>{LabelModal}</Button>
                ]}
            >
                <Form {...layout}>
                    <Item label="Id" hidden>
                        <Input name="permisoid" onChange={handleChange} value={permisos && permisos.permisoid}/>
                    </Item>
                    <Item label="Permiso">
                        <Input name="permisodescripcion" onChange={handleChange} value={permisos && permisos.permisodescripcion}/>
                    </Item>
                    <Item label="Ruta">
                        <Input name="permisoruta" onChange={handleChange} value={permisos && permisos.permisoruta}/>
                    </Item>
                    <Item label="Icono">
                        <Input addonAfter={<i className={permisos && permisos.permisoicono}></i>} name="permisoicono" onChange={handleChange} value={permisos && permisos.permisoicono}/>
                        
                    </Item>

                    {
                        LabelModal ==="Ingresar" &&
                        <>
                            <Item label="Rol">
                                <Select 
                                    onChange={(e)=>ObtenerSelect(e,'permisorolId')}
                                    size="default" 
                                    name="permisorolId"
                                    value={permisos && permisos.permisorolId}
                                >
                                    { LISTAR_ROLES }
                                </Select>
                            </Item>
                        </>
                    }
                    
                    <Item label="Padre">
                            <Select 
                                onChange={(e)=>ObtenerSelect(e,'permisopadreId')}
                                size="default" 
                                name="permisopadreId"
                                disabled={disablePadre}
                                value={permisos && permisos.permisopadreId}
                            >
                                { LISTAR_PADRES }
                            </Select>
                    </Item>
                    <Item label="Estado">
                            <Select 
                                onChange={(e)=>ObtenerSelect(e,'permisoestado')}
                                size="default" 
                                name="permisoestado"
                                value={permisos && permisos.permisoestado}
                            >
                                { LISTAR_ESTADOS }
                            </Select>
                    </Item>

                </Form>
            </Modal>

            
        </>
    )
}

export default PermisosPage


const useStyles = makeStyles((theme) => ({
    BtnIngresar:{
        margin:'10px 0px 10px 5%'
    },
    table: {
        margin: '0 auto',
        width: '90%'
    },
    ComboRol:{
        width:'200px',
        float:'right',
        margin:'10px 5% 10px 0px'
    }
}));
