import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import useAuth from '../../Auth/useAuth';


import { Drawer, Form, Button, Col, Row, Input, Select , Menu } from 'antd';

import { MailOutlined,LoginOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const { Option } = Select;
const Navbar = () => {

    const auth = useAuth();

    const [state, setState] = useState({
        current:'mail',
    });

    const [visible, setVisible] = React.useState(false)

    const { current } = state;


    const handleClick = (e) => {

        setState({
            ...state,
            current:  e.key,
        });
    };

    const showDrawer = () =>{
        setVisible(!visible);
    }



    const SUBMENU = (Padre) =>{

        const Validador = auth.Permisos().filter( Nav => {
            const result = Padre === Nav.Padre;
            return result;
        } );

        return(
                Validador.map((Nav) =>   
                        <Menu.Item key={Nav.Id} icon={<i className={Nav.Icono} ></i>}>
                            <NavLink exact to={Nav.Ruta}>{Nav.Descripcion}</NavLink>
                        </Menu.Item>
                )
        )
    } 

    const MENU = () =>{
        let Valor = 0;
        const Validador = auth.Permisos().filter( Nav => {
            const result = Valor == Nav.Padre;
            return result;
        } );
        // console.log(Validador)
        // console.log(auth.Permisos())
        return(
            Validador.map((Menu) => 
                <SubMenu  key={Menu.Id} icon={<i className={Menu.Icono}></i>} title={Menu.Descripcion}>
                    {SUBMENU(Menu.Id)}
                </SubMenu>
            )
        )
        
    }


    return (
        <>
        <Menu 
            onClick={handleClick} 
            selectedKeys={[current]} 
            mode="horizontal"
            theme={'Light'}
        >

                <Menu.Item key="Home" icon={<MailOutlined />}>
                    <NavLink exact to="/">Home</NavLink>
                    
                </Menu.Item>

                {
                    ! auth.isLogged() && (
                        <> 
                            <Menu.Item key="Login" icon={<LoginOutlined />}>
                                <NavLink exact to="/login" >Login</NavLink>
                            </Menu.Item>
                            
                        </>
                    )
                }

                {
                    auth.isLogged() && (
                        <>

                            {
                                auth.Permisos() !== null ?
                                    MENU()
                                :null
                            }

                            <Menu.Item key="Logout">
                                <Button  onClick={auth.logout} type="primary">
                                    Logout
                                </Button>
                            </Menu.Item>
                        </>
                    )
                }
        </Menu>
        
        <Drawer
            title="Create a new account"
            width={720}
            onClose={showDrawer}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <div
                style={{
                    textAlign: 'right',
                }}
                >
                <Button onClick={showDrawer} style={{ marginRight: 8 }}>
                    Cancel
                </Button>
                <Button onClick={showDrawer} type="primary">
                    Submit
                </Button>
                </div>
            }
        >
            <Form layout="vertical" hideRequiredMark>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Please enter user name' }]}
                        >
                        <Input placeholder="Please enter user name" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                        name="url"
                        label="Url"
                        rules={[{ required: true, message: 'Please enter url' }]}
                        >
                        <Input
                            style={{ width: '100%' }}
                            addonBefore="http://"
                            addonAfter=".com"
                            placeholder="Please enter url"
                        />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                        name="owner"
                        label="Owner"
                        rules={[{ required: true, message: 'Please select an owner' }]}
                        >
                        <Select placeholder="Please select an owner">
                            <Option value="xiao">Xiaoxiao Fu</Option>
                            <Option value="mao">Maomao Zhou</Option>
                        </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                        name="type"
                        label="Type"
                        rules={[{ required: true, message: 'Please choose the type' }]}
                        >
                        <Select placeholder="Please choose the type">
                            <Option value="private">Private</Option>
                            <Option value="public">Public</Option>
                        </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
        </>
    )
}

export default Navbar
