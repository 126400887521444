import React from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import useAuth from '../../Auth/useAuth'
import { makeStyles } from '@material-ui/core/styles';
import { Form, Input, Button, Checkbox, Spin ,message } from 'antd';
import 'antd/dist/antd.css';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import {ObtenerUsuario} from '../../Api/ObtenerUsuario';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
var md5 = require("md5"); 
const LoginPage = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [StateLoading, setStateLoading] = React.useState(false);
    const style = useStyles();
    const location = useLocation();
    const history = useHistory();
    const auth = useAuth();
    const PreviusUrl = location.state?.from;

    const [State, setState] = React.useState({
        Usuario: "",
        Clave:"",
    });

    const handleLogin = async () =>{
        setStateLoading(true);
        const Usuario = await ObtenerUsuario(State.Usuario,md5(State.Clave));
        if(Usuario && Usuario.length > 0 ){

            let Dia = moment().locale('es').format('dddd'); 
            let TiempoValidado = '';
            if(Dia ==="Saturday" || Dia ==="Sunday"){
                TiempoValidado = Usuario[0].Tiempo2;
            }else{
                TiempoValidado = Usuario[0].Tiempo;
            }
            // console.log(TiempoValidado);

            if( ValidarHorario(TiempoValidado, Usuario[0].Rol)){
                auth.login(Usuario[0]);
                setStateLoading(false);
                history.push(PreviusUrl || "/dashboard")
            }else{
                setStateLoading(false);
            }
            
        }else{
            message.error('Usuario o contraseña incorrecta');
            setStateLoading(false);
        }
        
    }

    const ValidarHorario = (Tiempo, Usuario) =>{
        var now = moment().locale('es').format('HH:mm:ss');
        var format = 'HH:mm:ss'
        let ArrayTiempo = Tiempo.split(',');

        let time = moment(now,format),
        Inicio = moment(ArrayTiempo[0], format),
        Fin = moment(ArrayTiempo[1], format);

        if ( (time.isBetween(Inicio, Fin) ) || Usuario ==="ADMINISTRADOR" || Usuario ==="SUPER ADMINISTRADOR") {
            return true
        } else {
            message.error('No es su horario establecido o no tiene acceso al sistema');
            return false
        }

    }

    const ObtenerUsuarios = (e) =>{
        const Usuario = e.target.value
        setState({
            ...State,
            Usuario: Usuario,
        });
    }

    const ObtenerClave = (e) =>{
        const Clave = e.target.value
        setState({
            ...State,
            Clave: Clave,
        });
    }

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };

    return (

        <>

            


            <Form
                name="normal_login"
                className={style.loginForm}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="username"
                    rules={[
                        {
                        required: true,
                        message: 'Por favor ingrese el usuario!',
                        },
                    ]}
                >
                <Input 
                    prefix={<UserOutlined />} 
                    placeholder="Usuario"
                    onChange={(e)=>ObtenerUsuarios(e)}
                />
                </Form.Item>
                <Form.Item
                name="password"
                rules={[
                    {
                    required: true,
                    message: 'Por favor ingrese la contraseña!',
                    },
                ]}
                >
                <Input
                    prefix={<LockOutlined/>}
                    type="password"
                    placeholder="Contraseña"
                    onChange={(e)=>ObtenerClave(e)}
                />
                </Form.Item>
                <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Recordar credenciales</Checkbox>
                </Form.Item>
        
                <p className={style.Forgot}>
                    ActuaLead
                </p>
                </Form.Item>
        
                <Form.Item>
                <Button type="primary" onClick={handleLogin} className={style.LoginButton}>
                    Log in
                </Button>
                </Form.Item>
                {   
                    StateLoading === true && (
                        <div className={style.Cargar}>
                            <Spin indicator={antIcon} />
                        </div>
                    )
                }
            </Form>
        </>
    )
}

export default LoginPage

const useStyles = makeStyles((theme) => ({
    loginForm:{
        marginTop:'100px',
        margin: '10% auto',
        maxWidth: '300px',
        "@media (max-width: 900px)": {
            marginTop:'150px'
        },
    },
    Forgot:{
        float: 'right',
        fontSize:'10px'
    },
    LoginButton:{
        width:'100%'
    },
    Cargar:{
        justifyContent:'center',
        textAlign:'center'
    },

}));