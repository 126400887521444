import React , {useState , useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { 
    Table , 
    Button , 
    Modal , 
    Input , 
    Form , 
    Space , 
    Select , 
    TimePicker , 
    notification , 
    Popconfirm ,
    message ,
    Tooltip,
    Badge
} from 'antd';
import { SearchOutlined,EyeInvisibleOutlined, EyeTwoTone  } from '@ant-design/icons';

import 'antd/dist/antd.css';
const UrlGet = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/get_methods/usuario/getUsuarios.php?Token=015ec0d618fe3fa9c7d0a1abda2b159c";
const UrlPost = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/post_methods/usuario/guardarUsuario.php";
const UrlUpdate = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/post_methods/usuario/actualizarUsuario.php";
const UrlDelete = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/post_methods/usuario/eliminarUsuario.php";
const UrlRoles = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/get_methods/usuario/getRoles.php?Token=015ec0d618fe3fa9c7d0a1abda2b159c";
const UrlEstados = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/get_methods/usuario/getEstados.php?Token=015ec0d618fe3fa9c7d0a1abda2b159c";
const { Option } = Select;
const {Item} = Form;
const layout = {
    labelCol:{
        span:5
    },
    wrapperCol:{
        span:20
    }
}
var md5 = require("md5"); 
const pagination = {
    pageSize: 5,
}

const UserPage = () => {
    const styles= useStyles();
    const [data ,setData] = useState([]);
    const [modal, setModal] = useState(false);

    const [loading ,setLoading] = useState(false);

    const [disable ,setDisable] = useState(false);

    const [roles ,setRoles] = useState([]);
    const [estados ,setEstados] = useState([]);

    const Usuarios = {
        token:'015ec0d618fe3fa9c7d0a1abda2b159c',
        userid:'',
        username:'',
        useridentificacion:'',
        userlastname:'',
        usermail:'',
        userpassword:'',
        userrol:'',
        usertiempo:'',
        usertiempo2:'',
        userestado:'',
    }

    useEffect(()=>{
        InicializarCombos();
    },[])

    useEffect(()=>{
        GetUsuarios();
        setLoading(true)
    },[])

    const openNotificationSuccess = (posicion,mensaje,descripcion) => {
        notification.success({
            message: mensaje,
            description: descripcion,
            posicion,
        });
    };

    const openNotificationError = (posicion,mensaje,descripcion) => {
        notification.error({
            message: mensaje,
            description: descripcion,
            posicion,
        });
    };


    const InicializarCombos = async () =>{

        await axios.get(UrlRoles)
        .then(response=>{
            setRoles(response.data.body)
        })

        await axios.get(UrlEstados)
        .then(response=>{
            setEstados(response.data.body)
        })
    }

    const [state,setState] = useState({
        searchText: '',
        searchedColumn: '',
    })

    const ObtenerTiempo = (tiempo,Opcion) =>{
        let Tiempo = tiempo.toString()
        setUsuario(usuario=>({
            ...usuario,
            [Opcion]: Tiempo
        }))
    }

    const ObtenerSelect = (Estado,Opcion) =>{
        // console.log(Estado)
        setUsuario(usuario=>({
            ...usuario,
            [Opcion]: Estado
        }))
    }

    const getColumnSearchProps = (dataIndex,Nombre) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                placeholder={`Buscar ${Nombre}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Buscar
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Resetear
                </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        render: text =>
            state.searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[state.searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),

    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setState(state=>({
            ...state,
            searchText:  selectedKeys[0],
            searchedColumn: dataIndex,
        }))
    };
    
    const handleReset = clearFilters => {
        clearFilters();
        setState(state=>({
            ...state,
            searchText: '',
        }))
    };

    const [usuario, setUsuario] = useState(Usuarios)

    const [LabelModal, setLabelModal] = useState("");

    const handleChange = (e) =>{
        const {name, value}= e.target;
        setUsuario(usuario=>({
            ...usuario,
            [name]: value
        }))
    }

    const GetUsuarios = async () =>{
        await axios.get(UrlGet)
        .then(response=>{
                setData(response.data.body)
                setLoading(false)
        })
    }

    const ValidarIngresoUsuario = () =>{
        if( 
            ValidarIdentificacion() && 
            ValidarNombre() && 
            ValidarApellido() && 
            ValidarRol() &&
            // ValidarTiempo() &&
            // ValidarTiempo2()  &&
            ValidarClave()
        ){
            return true;
        }
        return false;
    }

    const ValidarEdicionUsuario = () =>{
        if( 
            ValidarIdentificacion() && 
            ValidarNombre() && 
            ValidarApellido() && 
            ValidarRol() 
            // && ValidarTiempo() && 
            // ValidarTiempo2()
        ){
            return true;
        }
        return false;
    }



    const ValidarNombre = () =>{
        if(usuario.username ==='' || usuario.username ===null){
            message.warning('Ingrese el nombre del usuario');
            return false;
        }
        return true
    }

    const ValidarApellido = () =>{
        if(usuario.userlastname ==='' || usuario.userlastname ===null){
            message.warning('Ingrese el apellido del usuario');
            return false;
        }
        return true;
    }

    const ValidarIdentificacion = () =>{
        if(usuario.useridentificacion ==='' || usuario.useridentificacion ===null){
            message.warning('Ingrese la identificación');
            return false;
        }
        if(usuario.useridentificacion.length !==10){
            message.warning('La identificacion ingresada no es valida');
            return false;
        }
        return true;
    }

    const ValidarRol = () =>{
        if(usuario.userrolId ==='' || usuario.userrolId ===null){
            message.warning('Ingrese el rol');
            return false;
        }
        return true;
    }

    // const ValidarTiempo = () =>{
    //     if(usuario.usertiempo ==='' || usuario.usertiempo ===null){
    //         message.warning('Ingrese el horario de lunes a viernes');
    //         return false;
    //     }
    //     return true;
    // }

    // const ValidarTiempo2 = () =>{
    //     if(usuario.usertiempo2 ==='' || usuario.usertiempo2 ===null){
    //         message.warning('Ingrese el horario de fin de semana');
    //         return false;
    //     }
    //     return true;
    // }

    const ValidarClave = () =>{
        if(usuario.userpassword ==='' || usuario.userpassword ===null){
            message.warning('Ingrese la contraseña');
            return false;
        }
        return true;
    }

    const CrearUsuarios = async () =>{

        let Logueo = JSON.parse(localStorage.getItem('User'));
        let UsuarioLogueado = Logueo.Nombre+' '+Logueo.Apellido

        if(ValidarIngresoUsuario()){
            let Json = {
                token:'015ec0d618fe3fa9c7d0a1abda2b159c',
                nombre: usuario.username,
                apellido: usuario.userlastname,
                identificacion: usuario.useridentificacion,
                rol: usuario.userrolId,
                tiempo: usuario.usertiempo,
                tiempo2: usuario.usertiempo2,
                clave: md5(usuario.userpassword),
                usuario: UsuarioLogueado
            }
            await axios.post(UrlPost , Json)
            .then(response=>{
                if(response.data.status === "OK"){
                    openNotificationSuccess('topRight','Accion exitosa','El usuario ha sido creado correctamente.')
                }else{
                    openNotificationError('topRight','Error',response.data.message)
                }
                GetUsuarios();
                AbrirModal()
            })
        }
        
    }

    const EditarUsuarios = async () =>{
        let Logueo = JSON.parse(localStorage.getItem('User'));
        let UsuarioLogueado = Logueo.Nombre+' '+Logueo.Apellido

        if(ValidarEdicionUsuario()){
            let Json = {
                token:'015ec0d618fe3fa9c7d0a1abda2b159c',
                nombre: usuario.username,
                apellido: usuario.userlastname,
                identificacion: usuario.useridentificacion,
                rol: usuario.userrolId,
                estado: usuario.userestado,
                tiempo: usuario.usertiempo,
                tiempo2: usuario.usertiempo2,
                usuario:UsuarioLogueado
            }
            // console.log(Json)
            await axios.post(UrlUpdate , Json)
            .then(response=>{
                if(response.data.status === "OK"){
                    openNotificationSuccess('topRight','Accion exitosa','El usuario ha sido editado correctamente.')
                }else{
                    openNotificationError('topRight','Error','No se pudo editar el usuario!')
                }
                GetUsuarios();
                AbrirModal()
            })
        }

    }

    const confirm = (dato) =>{
        EliminarUsuario(dato);
    }

    const EliminarUsuario = async (dato) =>{
        let Logueo = JSON.parse(localStorage.getItem('User'));
        let UsuarioLogueado = Logueo.Nombre+' '+Logueo.Apellido
        let Json = {
            token:'015ec0d618fe3fa9c7d0a1abda2b159c',
            identificacion : dato.useridentificacion,
            usuario: UsuarioLogueado,
        }
        await axios.post(UrlDelete , Json)
        .then(response=>{
            if(response.data.status === "OK"){
                openNotificationSuccess('topRight','Accion exitosa','El usuario ha sido eliminado correctamente.')
            }else{
                openNotificationError('topRight','Error','No se pudo eliminar el usuario!')
            }
            GetUsuarios();
        })
    }

    const ControlModales = (data,name) =>{
        setUsuario(data)
        setLabelModal(name)
        AbrirModal();
        if(name==='Editar'){
            setDisable(true)
        }else{
            setDisable(false)
        }
    }

    const ControlOperaciones = (name) =>{

        const OPCION_OPERACIONES = {
            'Ingresar': () =>{ CrearUsuarios() },
            'Editar':() =>{ EditarUsuarios() },
        }
        OPCION_OPERACIONES[name]();
    }

    const AbrirModal=()=>{
        setModal(!modal);
    }

    const columns = [
        {
            title:'Id',
            dataIndex:'userid',
            key:'userid',
            ...getColumnSearchProps('userid','Id'),
            fixed: 'left',
            width: 100
        },
        {
            title:'Nombre',
            dataIndex:'username',
            key:'username',
            ...getColumnSearchProps('username','Nombre'),
            width: 150
        },
        {
            title:'Apellido',
            dataIndex:'userlastname',
            key:'userlastname',
            width: 150
        },
        {
            title:'Identificacion',
            dataIndex:'useridentificacion',
            key:'useridentificacion',
            ...getColumnSearchProps('useridentificacion','Identificacion'),
            width: 150
        },
        {
            title:'Rol',
            dataIndex:'userrol',
            key:'userrol',
            width: 150
        },
        {
            title:'Lunes a Viernes',
            dataIndex:'usertiempo',
            key:'usertiempo',
            width: 150
        },
        {
            title:'Sabados a Domingos',
            dataIndex:'usertiempo2',
            key:'usertiempo2',
            width: 180
        },
        {
            title:'Estado',
            dataIndex:'userestado',
            key:'userestado',
            width: 100
        },

        {
            title:'Acciones',
            key:'acciones',
            fixed: 'right',
            render: fila=>(
                <>
                    <Button type="primary" onClick={()=>ControlModales(fila,'Editar')}>Editar</Button> {" "}
                    <Popconfirm
                        placement="topRight"
                        title={'Desea eliminara este usuario?'}
                        onConfirm={()=>confirm(fila)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" danger>Eliminar</Button>
                    </Popconfirm>
                    
                </>
            ),
            width: 200
        },
    ]

    const Tiempo = (tiempo,opcion) =>{
        let Tiempo= tiempo.split(',');
        return Tiempo[opcion];
    }

    const LISTAR_ROLES = roles.map((roles) =>   
                                    <Option key={roles.key} value={roles.rolid} >{roles.roldescripcion}</Option>
                                );
    const LISTAR_ESTADOS = estados.map((estados) =>   
                                    <Option key={estados.key} value={estados.estadodescripcion} >{estados.estadodescripcion}</Option>
                                );

    return (
        <>  
            <Button className={styles.BtnIngresar} type="primary"  onClick={()=>ControlModales(Usuarios,'Ingresar')}>Ingresar</Button>

            <Table className={styles.table} loading={loading} columns={columns} key={data.key}  dataSource={data}  pagination={pagination}  scroll={{ y: 350 , x: 900 }} />

            <Modal
                visible={modal}
                title = {LabelModal + " Usuario"}
                destroyOnClose={true}
                onCancel={AbrirModal}
                centered
                footer={[
                    <Button onClick={AbrirModal}>Cancelar</Button>,
                    <Button type="primary" onClick={()=>ControlOperaciones(LabelModal)}>{LabelModal}</Button>
                ]}
            >
                <Form {...layout}>
                    <Item label="Id" hidden>
                        <Input name="userid" onChange={handleChange} value={usuario && usuario.userid}/>
                    </Item>
                    <Item label="Identificacion">
                        <Input name="useridentificacion" disabled={disable} onChange={handleChange} value={usuario && usuario.useridentificacion}/>
                    </Item>
                    <Item label="Nombre">
                        <Input name="username" onChange={handleChange} value={usuario && usuario.username}/>
                    </Item>
                    <Item label="Apellido">
                        <Input name="userlastname" onChange={handleChange} value={usuario && usuario.userlastname}/>
                    </Item>
                    <Item label="Rol">
                        <Select 
                            onChange={(e)=>ObtenerSelect(e,'userrolId')}
                            size="small" 
                            name="userrolId"
                            value={usuario && usuario.userrolId}
                        >
                            { LISTAR_ROLES }
                        </Select>
                    </Item>
                    <Item label={<Tooltip placement="topRight" title={'Lunes a viernes'}><Badge color={'yellow'} text={'Horario'}/></Tooltip>}>
                        <TimePicker.RangePicker 
                            name="usertiempo"
                            style={{width:'100%'}} 
                            onChange={
                                (e,timeString)=>{
                                    ObtenerTiempo(timeString,'usertiempo');
                                }
                            }
                            value={usuario.usertiempo !=="" ? [moment( Tiempo(usuario.usertiempo,0), 'HH:mm:ss'), moment( Tiempo(usuario.usertiempo,1), 'HH:mm:ss') ]:""}
                        />
                    </Item>
                    <Item label={<Tooltip placement="topRight" title={'Sabados a domingos'}><Badge color={'blue'} text={'Horario'}/></Tooltip>}>
                        <TimePicker.RangePicker 
                            name="usertiempo2"
                            style={{width:'100%'}} 
                            onChange={
                                (e,timeString)=>{
                                    ObtenerTiempo(timeString,'usertiempo2');
                                }
                            }
                            value={usuario.usertiempo2 !=="" ? [moment( Tiempo(usuario.usertiempo2,0), 'HH:mm:ss'), moment( Tiempo(usuario.usertiempo2,1), 'HH:mm:ss') ]:""}
                        />
                    </Item>
                    {
                        LabelModal ==="Editar" &&
                        <Item label="Estado">
                            <Select 
                                onChange={(e)=>ObtenerSelect(e,'userestado')}
                                size="small" 
                                name="userestado"
                                value={usuario && usuario.userestado}
                            >
                                { LISTAR_ESTADOS }
                            </Select>
                        </Item>
                        
                    }
                    {
                        LabelModal ==="Ingresar" &&
                        <>
                            <Item label="Contraseña">
                                <Input.Password
                                    name="userpassword" 
                                    onChange={handleChange} 
                                    value={usuario && usuario.userpassword}
                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </Item>
                        </>
                    }

                </Form>
            </Modal>

        </>
    );  
}
export default UserPage;


const useStyles = makeStyles((theme) => ({
    table: {
        margin: '0 auto',
        width: '90%'
    },
    BtnIngresar:{
        margin:'10px 0px 10px 5%'
    }
}));