import React, { useState , useEffect } from 'react'
import axios from 'axios';
import moment from 'moment';
import ExportExcel from 'react-export-excel';
import {makeStyles} from '@material-ui/core/styles';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import {GetDescargas} from '../../Api/GetDescargas'
import { 
    Row, Col , DatePicker , Typography , Select , Button , Spin , message , Drawer , notification , Radio
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const UrlGet = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/get_methods/aprobacion/ReporteAprobacionCartera.php?token=015ec0d618fe3fa9c7d0a1abda2b159c";
const UrlCombos = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/get_methods/aprobacion/getComboValidacion.php?token=015ec0d618fe3fa9c7d0a1abda2b159c";
const UrlActualizarDescargas = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/post_methods/exportar/AcualizarDescarga.php?Token=015ec0d618fe3fa9c7d0a1abda2b159c";
const { RangePicker } = DatePicker;
const { Text } = Typography;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ReportePage = () => {

    const ExcelFile = ExportExcel.ExcelFile;
    const ExcelSheet = ExcelFile.ExcelSheet;
    const ExcelColumn = ExcelFile.ExcelColumn;

    const styles= useStyles();
    const [state ,setState] = useState([]);

    const [datos ,setDatos] = useState([]);

    const [loading ,setLoading] = useState(false);

    const [loadingExportar ,setLoadingExportar] = useState(false);

    const [ocultar ,setOcultar] = useState(true);

    const [mostrarPivot ,setMostrarPivot] = useState(false);

    const [dataFiltrada ,setDataFiltrada] = useState([]);

    const [Validacion ,setValidacion] = useState([]);

    const [ocultarExportar ,setOcultarExportar] = useState(true);

    const [Empty ,setEmpty] = useState(true);

    const [consultar ,setConsultar] = useState({
        Fechas: '',
        Tipo: ''
    });
    const [exportar ,setExportar] = useState({
        Combo: ''
    });

    const [visible, setVisible] = useState(false);
    const [placement, setPlacement] = useState('right');

    const [value, setValue] = React.useState('SI');

    const onChange = e => {
        setOcultarExportar(true)
        setValue(e.target.value);
    };
    useEffect(()=>{
        Inicializar();
    },[])

    const showDrawer = () => {
        setVisible(true);
    };


    const onClose = () => {
        setVisible(false);
    };

    const Ocultar = () =>{
        setOcultar(!ocultar);
        let Display = 'block';
        if(ocultar){
            Display ='none';
        }
        let section = document.getElementsByClassName("pvtAxisContainer");
        let combo = document.getElementsByClassName("pvtRenderers");
        section[1].style.display = Display;
        combo[0].style.display = Display;
        // console.log(section)
    }

    const Inicializar = async () =>{
        await axios.get(UrlCombos)
        .then(response=>{
            setValidacion(response.data.body)
        }).catch(error => {
            console.log(error)
            message.warning('Por favor, evite hacer peticiones sin sentido de lo contrario se bloqueara la información');
        });
    }

    const LISTAR_VALIDADA = Validacion.map((Validacion) =>   
        <Option key ={Validacion.key} value={Validacion.label} >{Validacion.label}</Option>
    );


    const GetReporte = async () =>{

        if(ValidarReporte()){
            setLoading(true)
            let RangoFechas = consultar.Fechas;
            let ArrayFechas= RangoFechas.split(',');
    
            let Inicio = ArrayFechas[0];
            let Fin = ArrayFechas[1];
            let Tipo = consultar.Tipo;
            await axios.get(UrlGet+"&fecha_inicio="+Inicio+"&fecha_fin="+Fin+"&tipo="+Tipo)
            .then(response=>{

                if(response.data.body.length>0){
                    setDatos(response.data.body)
                    setLoading(false)
                    setEmpty(false)
                    setMostrarPivot(true)

                }else{
                    message.warning('Opps! no hay datos que mostrar');
                    setLoading(false)
                    setEmpty(true)
                }
                
            }).catch(error => {
                console.log(error)
                message.warning('Error al consultar, intente mas tarde');
            });
        }

    }

    const ValidarReporte = () =>{

        if(
            ValidarFechas() &&
            ValidarTipo()
        ){
            return true
        }
        return false
    }

    const ValidarFechas = () =>{
        if(consultar.Fechas ==='' || consultar.Fechas ===null){
            message.warning('Ingrese el rango de fechas');
            return false;
        }
        return true;
    }

    const ValidarTipo = () =>{
        if(consultar.Tipo ==='' || consultar.Tipo ===null){
            message.warning('Ingrese el tipo de gestion');
            return false;
        }
        return true;
    }


    const ObtenerTiempo = (tiempo) =>{
        let Tiempo = tiempo.toString()
        setConsultar(consultar=>({
            ...consultar,
            Fechas: Tiempo
        }))
    }

    const ObtenerTipo = (e) =>{
        setConsultar(consultar=>({
            ...consultar,
            Tipo: e
        }))
    }

    const ValidarExportar = (e) =>{
        setExportar(Exportar=>({
            ...Exportar,
            Combo: e
        }))
    }

    const openNotificationSuccess = (posicion,mensaje,descripcion) => {
        notification.success({
            message: mensaje,
            description: descripcion,
            posicion,
        });
    };

    const openNotificationError = (posicion,mensaje,descripcion) => {
        notification.error({
            message: mensaje,
            description: descripcion,
            posicion,
        });
    };

    const GenerarReporte = async () =>{
        setLoadingExportar(true)
        let Numeros =  dataFiltrada.map((Numeros) => Numeros.Celular);

        let Json = {
            token:'015ec0d618fe3fa9c7d0a1abda2b159c',
            Numeros: Numeros,
        }

        await axios.post(UrlActualizarDescargas , Json)
        .then(response=>{
            if(response.data.status === "OK"){
                openNotificationSuccess('topRight','Accion exitosa','Se actualizo la información correctamente.')
                setOcultarExportar(true)
                setLoadingExportar(false)
            }else{
                openNotificationError('topRight','Error','No se pudo actualizar la información, por favor vuelva a exportar')
                setOcultarExportar(false)
                setLoadingExportar(false)
            }
        })

        
    }

    const ValidarExportacion = async () =>{
        
        if(ValidarComboValidacion()){
            setLoadingExportar(true)
            let Fechas = consultar.Fechas;
            let Array = Fechas.split(',');
            let FechaInicial= Array[0];
            let FechaFinal= Array[1];
            let Validacion = exportar.Combo;
            let Todo = value;
            const Descargas = await GetDescargas(FechaInicial,FechaFinal,Validacion,Todo)
            // console.log(Descargas)
            if(Descargas.length>0){
                setDataFiltrada(Descargas)
                setOcultarExportar(false)
                setLoadingExportar(false)
                message.success('Se han encontrado ' + Descargas.length + ' registros para exportar');
            }else{
                setOcultarExportar(true)
                setLoadingExportar(false)
                message.warning('No se encontro ningun registro');
                
            }
        }
    }

    const ValidarComboValidacion = () =>{
        if(exportar.Combo =='' || exportar.Combo == null){
            message.warning('Debe indicar el tipo de validación');
            return false
        }
        return true
    }

    const Tiempo = (tiempo,opcion) =>{
        let Tiempo= tiempo.split(',');
        // console.log(Tiempo)
        return Tiempo[opcion];
    }


    return (
        <>
            <div className={styles.Contenedor}>
                <Row>
                    <Col className={styles.Fechas} span={6}>
                        {
                            mostrarPivot && (
                                <Row className={styles.Ocultar}>
                                    <Button className={styles.Rango} onClick={()=>Ocultar()} type="secundary" >
                                        {
                                            ocultar ?
                                            "Ocultar parametros"
                                            :
                                            "Mostrar parametros"
                                        }
                                    </Button>
                                </Row>
                            )
                        }
                        <Row className={styles.Campos}>
                            <Text code>Rango de fechas:</Text>
                        </Row>
                        <Row className={styles.Campos}>
                            <RangePicker 
                                showTime 
                                className={styles.Rango} 
                                onChange={
                                    (e,timeString)=>{
                                        ObtenerTiempo(timeString);
                                    }
                                }
                                showTime={{
                                    hideDisabledOptions: true,
                                    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                                }}
                                
                            />
                        </Row>
                        <Row className={styles.Campos}>
                            <Text code>Tipo de busqueda:</Text>
                        </Row>

                        <Row className={styles.Campos}>
                            <Select 
                                className={styles.Rango}
                                size="default" 
                                name="tipo"
                                onChange = {(e)=>ObtenerTipo(e)}
                            >
                                <Option key='1' value='created_at' >Fecha ingreso</Option>
                                <Option key='2' value='updated_at' >Fecha gestion</Option>
                            </Select>
                        </Row>

                        <Row className={styles.Campos}>
                            <Button className={styles.Rango} onClick={()=>GetReporte()} type="primary" >Consultar</Button>
                        </Row>
                        {
                            mostrarPivot && (
                                <Row className={styles.Campos}>
                                    <Button  className={styles.Rango} type="primary" onClick={showDrawer}>
                                        Exportar
                                    </Button>
                                </Row>    
                            )
                        }   

                    </Col>
                    {
                        
                            loading  ?
                            <Col className={styles.Pivot} span={17}>
                                <Spin className={styles.Spin}/> 
                            </Col>
                                
                            :
                            mostrarPivot && Empty === false ? (
                                <Col className={styles.Pivot} span={17}>
                                            <PivotTableUI
                                                data={datos}
                                                rows={['usuario']}
                                                cols ={['validacion']}
                                                onChange={s => setState(s)}
                                                {...state}
                                                hiddenAttributes ={
                                                    ['client_tariff','client_type','created_at','database_name','discharge_date','est_linea','fecha_ingreso']
                                                }
                                            /> 
                                </Col>
                            )
                            :
                            null
                    
                    }
                    
                </Row>

                <Drawer
                    title="Exportar datos a excel"
                    placement={placement}
                    width={500}
                    onClose={onClose}
                    visible={visible}
                >

                    <Text  code>FILTRAR POR FECHA</Text>
                    <div className={styles.Campos}>
                        
                        <Radio.Group onChange={onChange} value={value}>
                            <Radio value={'SI'}>SI</Radio>
                            <Radio value={'NO'}>NO</Radio>
                        </Radio.Group>
                    </div>
                    
                    {   
                        value==='SI' && (
                            <div className={styles.Campos}>
                                <Text  code>RANGO DE FECHAS</Text>
                                <Row className={styles.Campos}>
                                    <RangePicker 
                                        showTime 
                                        className={styles.Rango} 
                                        onChange={
                                            (e,timeString)=>{
                                                ObtenerTiempo(timeString);
                                            }
                                        }
                                        disabled={true}
                                        value={consultar.Fechas !=="" ? [moment( Tiempo(consultar.Fechas,0), 'YYYY-MM-DD HH:mm:ss'), moment( Tiempo(consultar.Fechas,1), 'YYYY-MM-DD HH:mm:ss') ]:""}
                                    />
                                </Row>
                            </div>
                        )
                    }
                    
                    <div className={styles.Campos}>
                        <Text code>VALIDACION</Text>
                        <Select 
                            className={styles.Rango}
                            placeholder="SELECCIONE"
                            size={'default'}
                            name="Validacion"
                            onChange = {(e)=>ValidarExportar(e)}
                            value={exportar.Combo}
                        >
                            { LISTAR_VALIDADA }
                        </Select>
                    </div>

                    <div className={styles.Campos}>
                        <Button  className={styles.Rango} type="primary" onClick={async()=>ValidarExportacion()}>
                            Validar
                        </Button>
                    </div>
                    <div className={styles.Campos}>
                        <ExcelFile
                            className={styles.Excel}
                            element={
                                <Button disabled={ocultarExportar} className={styles.Rango} type="primary" onClick={()=>GenerarReporte()}>
                                    Exportar
                                </Button>
                            }
                            filename="Cartera"
                        >
                            <ExcelSheet 
                                data={dataFiltrada}
                                name="Datos filtrados"                      
                            >
                                <ExcelColumn
                                    label="IDENTIFICACION" value="Identificacion"
                                />
                                <ExcelColumn
                                    label="NOMBRE" value="Nombre"
                                />
                                <ExcelColumn
                                    label="CELULAR" value="Celular"
                                />
                                <ExcelColumn
                                    label="CIUDAD" value="Ciudad"
                                />
                                <ExcelColumn
                                    label="TARIFA" value="Tarifa"
                                />
                                <ExcelColumn
                                    label="OTROS"
                                />
                                <ExcelColumn
                                    label="OTROS1" value="Otros1"
                                />

                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                    <div className={styles.CargaExportar}>
                        {
                            loadingExportar && (
                                <Spin indicator={antIcon} />
                            )
                        }
                    </div>
                </Drawer>
                
            </div>
        </>
    )
}

export default ReportePage


const useStyles = makeStyles((theme) => ({
    Contenedor:{
        textAlign:'center',
    },
    Fechas:{
        padding:'12px',
    },
    Campos:{
        marginTop:'10px'
    },
    Rango:{
        marginTop:'12px',
        width:'100%'
    },
    Pivot:{
        marginTop:'20px',
        textAlign:'center'
    },
    Spin:{
        marginTop:'100px',
        textAlign:'center'
    },
    Excel:{
        margin:'12px'
    },
    CargaExportar:{
        textAlign:'center',
        marginTop:'20px'
    }
}));
