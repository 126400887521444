import React from 'react'
import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';

const Error404 = () => {
    const history = useHistory();

    const Accion = () =>{
        history.push("/dashboard");
    }
    return (
        <Result
            status="404"
            title="404"
            subTitle="Lo sentimos, la página que visitaste no existe."
            extra={
                <Button type="primary" onClick={Accion} >Retroceder</Button>
            }
        />
    )
}

export default Error404
