import React from 'react'
import { Route , Redirect, useLocation } from 'react-router-dom';
import useAuth from '../Auth/useAuth';

import AccesoDenegado from '../Pages/AccesoDenegado';
// import { LoadingOutlined } from '@ant-design/icons';
const AdminRoute = ({ component : Component, ...rest }) => {
    const auth =  useAuth();
    // const location = useLocation();
    return (
        <Route {...rest}>
            {
                auth.isLogged() && auth.Validacion(rest) === true ?
                <Component/>
                :
                // <Redirect to={{pathname:"/AccesoDenegado" , state:{from:location}}}/>
                <AccesoDenegado/>
            }
        </Route>
    )
}

export default AdminRoute