async function ObtenerPermisos(Rol){
    const UrlApi ='https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/get_methods/usuario/getPermisos.php?Token=015ec0d618fe3fa9c7d0a1abda2b159c&Rol='+Rol;
        try{
            let response= await fetch(UrlApi);
            let responseJson= await response.json();
            let res= await responseJson.body;
            return res;
        }catch(error){
            console.error(`ERROR LOGIN ES :  ${error}`)
        }
}
export  {ObtenerPermisos};