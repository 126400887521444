import React from 'react'
import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';
const AccesoDenegado = () => {
    const history = useHistory();

    const Accion = () =>{
        history.push("/dashboard");
    }
    return (
        <Result
            status="403"
            title="403"
            subTitle="Lo sentimos, No tiene permisos para acceder a esta página."
            extra={
                <Button type="primary" onClick={Accion} >Retroceder</Button>
            }
        />
    )
}

export default AccesoDenegado
