import React , {useState , useEffect}  from 'react'
import {makeStyles} from '@material-ui/core/styles';
// import Highlighter from 'react-highlight-words';
import axios from 'axios';
import { 
    Table , 
    Button , 
    Modal , 
    Input , 
    Form , 
    Select , 
    notification , 
    Popconfirm ,
    message
} from 'antd';

import 'antd/dist/antd.css';
const pagination = {
    pageSize: 5,
}
const layout = {
    labelCol:{
        span:5
    },
    wrapperCol:{
        span:20
    }
}
const {Item} = Form;
const UrlGet = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/get_methods/usuario/getRoles.php?Token=015ec0d618fe3fa9c7d0a1abda2b159c";
const UrlPost = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/post_methods/usuario/guardarRol.php";
const UrlUpdate = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/post_methods/usuario/actualizarRol.php";
const UrlDelete = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/post_methods/usuario/eliminarRol.php";
const UrlEstados = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/get_methods/usuario/getEstados.php?Token=015ec0d618fe3fa9c7d0a1abda2b159c";
const { Option } = Select;
const RolesPage = () => {

    const styles= useStyles();

    const [data ,setData] = useState([]);
    const [loading ,setLoading] = useState(false);
    const [estados ,setEstados] = useState([]);

    const Roles = {
        token:'015ec0d618fe3fa9c7d0a1abda2b159c',
        rolid:'',
        roldescripcion:'',
        rolestado:'',
    }

    const [rol, setRol] = useState(Roles)
    const [LabelModal, setLabelModal] = useState("");
    const [modal, setModal] = useState(false);

    const openNotificationSuccess = (posicion,mensaje,descripcion) => {
        notification.success({
            message: mensaje,
            description: descripcion,
            posicion,
        });
    };

    const openNotificationError = (posicion,mensaje,descripcion) => {
        notification.error({
            message: mensaje,
            description: descripcion,
            posicion,
        });
    };

    useEffect(()=>{
        InicializarCombos();
    },[])

    useEffect(()=>{
        GetUsuarios();
        setLoading(true)
    },[])

    const InicializarCombos = async () =>{

        await axios.get(UrlEstados)
        .then(response=>{
            setEstados(response.data.body)
        })
    }

    const LISTAR_ESTADOS = estados.map((estados) =>   
                                    <Option key={estados.key} value={estados.estadodescripcion} >{estados.estadodescripcion}</Option>
                                );

    const GetUsuarios = async () =>{
        await axios.get(UrlGet)
        .then(response=>{
                setData(response.data.body)
                setLoading(false)
        })
    }

    const AbrirModal=()=>{
        setModal(!modal);
    }

    const ControlModales = (data,name) =>{
        setRol(data)
        setLabelModal(name)
        AbrirModal();
    }

    const confirm = (dato) =>{
        EliminarRol(dato);
    }

    const EliminarRol = async (dato) =>{

        let Json = {
            token:'015ec0d618fe3fa9c7d0a1abda2b159c',
            id : dato.rolid
        }
        await axios.post(UrlDelete , Json)
        .then(response=>{
            if(response.data.status === "OK"){
                openNotificationSuccess('topRight','Accion exitosa','El rol ha sido eliminado correctamente.')
            }else{
                openNotificationError('topRight','Error','No se pudo eliminar el rol!')
            }
            GetUsuarios();
        })
    }

    const ControlOperaciones = (name) =>{

        const OPCION_OPERACIONES = {
            'Ingresar': () =>{ CrearRol() },
            'Editar':() =>{ EditarRol() },
        }
        OPCION_OPERACIONES[name]();
    }
    
    const CrearRol = async () =>{

        if(ValidacionRol()){
            let Json = {
                token:'015ec0d618fe3fa9c7d0a1abda2b159c',
                rol: rol.roldescripcion,
                estado: rol.rolestado,
            }
            await axios.post(UrlPost , Json)
            .then(response=>{
                if(response.data.status === "OK"){
                    openNotificationSuccess('topRight','Accion exitosa','El rol ha sido creado correctamente.')
                }else{
                    openNotificationError('topRight','Error',response.data.message)
                }
                GetUsuarios();
                AbrirModal()
            })
        }
        
    }

    const EditarRol = async () =>{

        if(ValidacionRol()){
            let Json = {
                token:'015ec0d618fe3fa9c7d0a1abda2b159c',
                id: rol.rolid,
                rol: rol.roldescripcion,
                estado: rol.rolestado
            }
            await axios.post(UrlUpdate , Json)
            .then(response=>{
                if(response.data.status === "OK"){
                    openNotificationSuccess('topRight','Accion exitosa','El rol ha sido editado correctamente.')
                }else{
                    openNotificationError('topRight','Error','No se pudo editar el rol!')
                }
                GetUsuarios();
                AbrirModal()
            })
        }

    }

    const ValidacionRol = () =>{
        if( 
            ValidarRol() && 
            ValidarEstado()
        ){
            return true;
        }
        return false;
    }

    const ValidarRol = () =>{
        if(rol.roldescripcion ==='' || rol.roldescripcion ===null){
            message.warning('Ingrese el nombre del rol');
            return false;
        }
        return true;
    }
    const ValidarEstado = () =>{
        if(rol.rolestado ==='' || rol.rolestado ===null){
            message.warning('Ingrese el estado del rol');
            return false;
        }
        return true;
    }

    const handleChange = (e) =>{
        const {name, value}= e.target;
        setRol(rol=>({
            ...rol,
            [name]: value
        }))
    }

    const ObtenerSelect = (Estado,Opcion) =>{
        setRol(rol=>({
            ...rol,
            [Opcion]: Estado
        }))
    }

    const columns = [
        {
            title:'Id',
            dataIndex:'rolid',
            key:'rolid',
            fixed: 'left',
            width: 100
        },
        {
            title:'Rol',
            dataIndex:'roldescripcion',
            key:'roldescripcion',
            width: 200
        },
        {
            title:'Estado',
            dataIndex:'rolestado',
            key:'userlastname',
            width: 200
        },
        {
            title:'Acciones',
            key:'acciones',
            fixed: 'right',
            render: fila=>(
                <>
                    <Button type="primary" onClick={()=>ControlModales(fila,'Editar')}>Editar</Button> {" "}
                    <Popconfirm
                        placement="topRight"
                        title={'Desea eliminara este usuario?'}
                        onConfirm={()=>confirm(fila)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" danger>Eliminar</Button>
                    </Popconfirm>
                    
                </>
            ),
            width: 200
        },
    ]

    return (
        <>
            <div className={styles.Imagen}>
                {/* {
                    JSON.stringify(data)
                } */}
                <Button className={styles.BtnIngresar} type="primary"  onClick={()=>ControlModales(Roles,'Ingresar')}>Ingresar</Button>
                <Table className={styles.table} loading={loading} columns={columns} key={data.key}  dataSource={data}  pagination={pagination}  scroll={{ y: 350 , x: 900 }} />
            </div>

            <Modal
                visible={modal}
                title = {LabelModal + " Rol"}
                destroyOnClose={true}
                onCancel={AbrirModal}
                centered
                footer={[
                    <Button onClick={AbrirModal}>Cancelar</Button>,
                    <Button type="primary" onClick={()=>ControlOperaciones(LabelModal)}>{LabelModal}</Button>
                ]}
            >
                <Form {...layout}>
                    <Item label="Id" hidden>
                        <Input name="rolid" onChange={handleChange} value={rol && rol.rolid}/>
                    </Item>
                    <Item label="Rol">
                        <Input name="roldescripcion" onChange={handleChange} value={rol && rol.roldescripcion}/>
                    </Item>
                    <Item label="Estado">
                        {/* <Input name="rolestado" onChange={handleChange} value={rol && rol.rolestado}/> */}
                            <Select 
                                onChange={(e)=>ObtenerSelect(e,'rolestado')}
                                size="default" 
                                name="rolestado"
                                value={rol && rol.rolestado}
                            >
                                { LISTAR_ESTADOS }
                            </Select>
                    </Item>

                </Form>
            </Modal>
        </>
    )
}

export default RolesPage


const useStyles = makeStyles((theme) => ({
    BtnIngresar:{
        margin:'10px 0px 10px 5%'
    },
    table: {
        margin: '0 auto',
        width: '90%'
    },
}));
