import React , { useState, useEffect } from 'react'
import {makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import moment from 'moment';
import useAuth from '../../Auth/useAuth';
import 'font-awesome/css/font-awesome.min.css';
import { 
    Button,
    Image,
    Input,
    Select,
    Typography,
    message,
    notification,
    Modal
} from 'antd';
import { UserOutlined ,InfoCircleOutlined ,SmileOutlined,PushpinOutlined} from '@ant-design/icons';

const { Text } = Typography;

const UrlCombos = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/get_methods/aprobacion/getparameters.php?token=015ec0d618fe3fa9c7d0a1abda2b159c";
const UrlObtenerNumero = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/get_methods/aprobacion/getDataAprobacionCartera.php?token=015ec0d618fe3fa9c7d0a1abda2b159c";
const UrlGuardar = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/post_methods/aprobacion/ActualizarDatosAprobacionCartera.php";
const UrlObtenerUsuario = "https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/get_methods/usuario/getUsuarioById.php?Token=015ec0d618fe3fa9c7d0a1abda2b159c";
const { Option } = Select;
const DashboardPage = () => {

    const auth = useAuth();

    const styles= useStyles();

    const [state, setSatate] = useState({
        Celular : "",
        Estado:"",
        TipoPlan:"",
        FacturaServicio:"",
        AdendumEquipo:"",
        BonoFidelizacion:"",
        Ruc:"",
        Operadora:"",
        Cupo:0
    })

    const [stateInfo, setSatateInfo] = useState({
        Indetificacion : "",
        Nombre:"",
        Ciudad:"",
    })

    // const [stateUsuario, setSatateUsuario] = useState([])

    const [Estados ,setEstados] = useState([]);
    const [TipoPlan ,setTipoPlan] = useState([]);
    const [FacturaServicio ,setFacturaServicio] = useState([]);
    const [AdendumEquipo ,setAdendumEquipo] = useState([]);
    const [BonoFidelizacion ,setBonoFidelizacion] = useState([]);
    const [Ruc ,setRuc] = useState([]);
    const [Operadora ,setOperadora] = useState([]);

    const [ValidarPrepago ,setValidarPrepago] = useState(false);
    const [ValidarEstadoInactivo ,setValidarEstadoInactivo] = useState(false);

    
    const [DesabilitarBoton ,setDesabilitarBoton] = useState(false);

    //ESTADOS PARA EL MODAL DE INFORMACION
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(()=>{
        Inicializar();
    },[])

    const Inicializar = async () =>{

        await axios.get(UrlCombos)
        .then(response=>{
            setEstados(response.data.body[0].Estados)
            setTipoPlan(response.data.body[1].TipoPlan)
            setFacturaServicio(response.data.body[2].FacturaServicio)
            setAdendumEquipo(response.data.body[3].AdendumEquipo)
            setBonoFidelizacion(response.data.body[4].BonoFidelizacion)

            setRuc(response.data.body[5].Ruc)
            setOperadora(response.data.body[6].Operadora)
            
        }).catch(error => {
            console.log(error)
            message.warning('Por favor, evite hacer peticiones sin sentido de lo contrario se bloqueara la información');
        });

    }

    const ObtenetUsuario = async () =>{
        let Logueo = JSON.parse(localStorage.getItem('User'));
        let UsuarioId = Logueo.Identificacion
        let Datos = {
            Tiempo: '',
            Tiempo2: '',
            Rol : ''
        }
        await axios.get(UrlObtenerUsuario+"&Identificacion="+UsuarioId)
        .then(response=>{
            Datos.Tiempo = response.data.body[0].usertiempo;
            Datos.Tiempo2 = response.data.body[0].usertiempo2;
            Datos.Rol = response.data.body[0].userrol
        })
        return Datos
    }

    const ObtenerNumero = async () =>{
        setDesabilitarBoton(true)
        let Logueo = JSON.parse(localStorage.getItem('User'));
        let UsuarioId = Logueo.id
        await axios.get(UrlObtenerNumero+"&Empleado="+UsuarioId)
        .then(response=>{
            console.log(response.data);
            setSatate(usuario=>({
                ...usuario,
                Celular: response.data.body[0].phone
            }))
            setSatateInfo(Info=>({
                ...Info,
                Indetificacion: response.data.body[0].client_identification,
                Nombre: response.data.body[0].client_name,
                Ciudad: response.data.body[0].client_city
            }))
        })
    }

    const handleChangeCombos = (e,name) =>{
        setSatate(usuario=>({
            ...usuario,
            [name]: e
        }))

        if(name==="TipoPlan"){
            ValidarTipoPlan(e);
        }

        if(name==="Estado"){
            ValidarEstado(e)
        }
        
        
    }
    const handleChangeInputs = (e) =>{
        const {name, value}= e.target;
        setSatate(usuario=>({
            ...usuario,
            [name]: value
        }))
    }

    const handleChangeInputCupo = (e) =>{
        const {name, value}= e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '') {
            setSatate(usuario=>({
                ...usuario,
                [name]: value
            }))
        }
    }

    const LISTAR_ESTADOS = Estados.map((Estados) =>   
        <Option key ={Estados.key} value={Estados.label} >{Estados.label}</Option>
    );
    const LISTAR_TIPO_PLAN = TipoPlan.map((TipoPlan) =>   
        <Option key ={TipoPlan.key} value={TipoPlan.label} >{TipoPlan.label}</Option>
    );
    const LISTAR_FACTURA_SERVICIO = FacturaServicio.map((FacturaServicio) =>   
        <Option key ={FacturaServicio.key} value={FacturaServicio.label} >{FacturaServicio.label}</Option>
    );
    const LISTAR_ADENDUM_EQUIPO = AdendumEquipo.map((AdendumEquipo) =>   
        <Option key ={AdendumEquipo.key} value={AdendumEquipo.label} >{AdendumEquipo.label}</Option>
    );
    const LISTAR_BONO_FIDELIZACION = BonoFidelizacion.map((BonoFidelizacion) =>   
        <Option key ={BonoFidelizacion.key} value={BonoFidelizacion.label} >{BonoFidelizacion.label}</Option>
    );
    const LISTAR_RUC = Ruc.map((Ruc) =>   
        <Option key ={Ruc.key} value={Ruc.label} >{Ruc.label}</Option>
    );
    const LISTAR_OPERADORA = Operadora.map((Operadora) =>   
        <Option key ={Operadora.key} value={Operadora.label} >{Operadora.label}</Option>
    );

    const openNotificationSuccess = (posicion,mensaje,descripcion) => {
        notification.success({
            message: mensaje,
            description: descripcion,
            posicion,
        });
    };

    const openNotificationError = (posicion,mensaje,descripcion) => {
        notification.error({
            message: mensaje,
            description: descripcion,
            posicion,
        });
    };

    const LIMPIAR_COMBOS = () =>{
        setSatate(usuario=>({
            ...usuario,
            Celular:"",
            Estado:"",
            TipoPlan:"",
            FacturaServicio:"",
            AdendumEquipo:"",
            BonoFidelizacion:""
        }))
    }

    const ValidarHorario = async () =>{

        const Data = await ObtenetUsuario();

        let Tiempo = Data.Tiempo;
        let Tiempo2 = Data.Tiempo2;
        let Usuario = Data.Rol;

        let Dia = moment().locale('es').format('dddd'); 
        let TiempoValidado = '';
        if(Dia ==="Saturday" || Dia ==="Sunday"){
            TiempoValidado = Tiempo2;
        }else{
            TiempoValidado = Tiempo;
        }
        // console.log(TiempoValidado);

        var now = moment().locale('es').format('HH:mm:ss');
        var format = 'HH:mm:ss'
        let ArrayTiempo = TiempoValidado.split(',');

        let time = moment(now,format),
        Inicio = moment(ArrayTiempo[0], format),
        Fin = moment(ArrayTiempo[1], format);

        if (time.isBetween(Inicio, Fin) || Usuario === "ADMINISTRADOR" || Usuario ==="SUPER ADMINISTRADOR") {
            return true
        }else{
            message.error('No es su horario establecido');
            auth.logout();
            return false
        }
    }

    const Guardar = async () =>{

        

            if(state.Celular !==''){

                if(
                    ( 
                        state.Estado !=='' &&
                        state.TipoPlan !=='' &&
                        state.FacturaServicio !=='' &&
                        state.AdendumEquipo !=='' &&
                        state.BonoFidelizacion !=='' &&
                        state.Ruc !=='' &&
                        state.Operadora !=='' &&
                        state.Cupo !==''
                    ) || 
                    (ValidarEstadoInactivo) ||
                    (ValidarPrepago && state.Estado !=='')
                ){

                    if( await ValidarHorario() === true ){
    
                        let Logueo = JSON.parse(localStorage.getItem('User'));
                        let UsuarioIng = Logueo.Nombre + ' ' + Logueo.Apellido;
                        const JsonDatos = {
                            token: "015ec0d618fe3fa9c7d0a1abda2b159c",
                            phone: state.Celular,
                            estado : state.Estado,
                            previo_pago : state.TipoPlan,
                            factura_servicio : state.FacturaServicio,
                            adendum_equipo : state.AdendumEquipo,
                            bono_fidelizacion : state.BonoFidelizacion,
                            ruc: state.Ruc,
                            operadora: state.Operadora,
                            cupo: state.Cupo,
                            usuario : UsuarioIng
                        }
                        console.log(JsonDatos)
                        await axios.post(UrlGuardar , JsonDatos)
                        .then(response=>{
                            if(response.data.status === "OK"){
                                openNotificationSuccess('topRight','Accion exitosa','Ha sido validado correctamente.')
                                LIMPIAR_COMBOS();
                                ObtenerNumero();
                            }else{
                                openNotificationError('topRight','Error',response.data.message)
                            }
                        })

                    }
    
                }else{
                    message.warning('Llene los campos requeridos');
                }
    
            }else{
                message.warning('Falta el numero de telefono');
            }
    }

    
    
    const ValidarEstado = (e) =>{
        if(e ==='INACTIVO'){
            setSatate(usuario=>({
                ...usuario,
                TipoPlan:"",
                FacturaServicio:"",
                AdendumEquipo:"",
                BonoFidelizacion:""
            }))
            setValidarPrepago(true)
            setValidarEstadoInactivo(true)
        }else{
            setValidarPrepago(false)
            setValidarEstadoInactivo(false)
        }
    }

    const ValidarTipoPlan = (TipoPlan) =>{
        if(TipoPlan ==='PREPAGO'){
            setSatate(usuario=>({
                ...usuario,
                FacturaServicio:"",
                AdendumEquipo:"",
                BonoFidelizacion:""
            }))

            setValidarPrepago(true)
        }else{
            setValidarPrepago(false)
        }
    }

    const VerInfomacion = () =>{
        showModal()
    }


    return (
        <>
            <div className={styles.Imagen}>
                <Image
                    preview={false}
                    width={'4%'}
                    src="https://validacion.ventasvirtuales.com.ec/Ws/img/ActuaLead.png"
                />
            </div>

            <div>
                <Button 
                    className={styles.BtnIngresar} 
                    type="primary"  
                    onClick={()=>ObtenerNumero()}
                    disabled={DesabilitarBoton}
                >Iniciar
                </Button>
            </div>

            <div className={styles.Contenedor}>

                <div className={styles.InfoTelefono}>
                    <Text code>TELEFONO</Text>
                        <Input 
                            className={styles.Cuadros}
                            name="Celular" 
                            onChange={handleChangeInputs} 
                            disabled={true}
                            value={state.Celular}
                            suffix={
                                <Button style={{border:'none'}} size={'small'} shape="circle" icon={<InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}  onClick={()=>VerInfomacion()} />
                            }
                        />
                </div>

                <div className={styles.Info}>
                    <Text  code>OPERADORA</Text>
                    <Select 
                        className={styles.Cuadros}
                        onChange={(e)=>handleChangeCombos(e,'Operadora')}
                        placeholder="SELECCIONE"
                        // disabled={ValidarPrepago}
                        value={state.Operadora}
                        size={'default'}
                    >
                        { LISTAR_OPERADORA }
                    </Select>  
                </div>

                <div className={styles.Info}>
                    <Text  code>ESTADO</Text>
                    <Select 
                        className={styles.Cuadros}
                        onChange={(e)=>handleChangeCombos(e,'Estado')}
                        name="Celular"
                        placeholder="SELECCIONE"
                        size={'default'}
                        value={state.Estado}
                    >
                        { LISTAR_ESTADOS }
                    </Select> 
                </div>

                <div className={styles.Info}>
                    <Text  code>TIPO PLAN</Text>
                    <Select 
                        className={styles.Cuadros}
                        onChange={(e)=>handleChangeCombos(e,'TipoPlan')}
                        placeholder="SELECCIONE"
                        disabled={ValidarEstadoInactivo}
                        value={state.TipoPlan}
                        size={'default'}
                    >
                        { LISTAR_TIPO_PLAN }
                    </Select> 
                </div>

                <div className={styles.Info}>
                    <Text  code>FACTURA SERVICIO</Text>
                    <Select 
                        className={styles.Cuadros}
                        onChange={(e)=>handleChangeCombos(e,'FacturaServicio')}
                        placeholder="SELECCIONE"
                        disabled={ValidarPrepago}
                        value={state.FacturaServicio}
                        size={'default'}
                    >
                        { LISTAR_FACTURA_SERVICIO }
                    </Select>  
                </div>

                <div className={styles.Info}>
                    <Text  code>ADENDUM EQUIPO</Text>
                    <Select 
                        className={styles.Cuadros}
                        onChange={(e)=>handleChangeCombos(e,'AdendumEquipo')}
                        placeholder="SELECCIONE"
                        disabled={ValidarPrepago}
                        value={state.AdendumEquipo}
                        size={'default'}
                    >
                        { LISTAR_ADENDUM_EQUIPO }
                    </Select>  
                </div>

                <div className={styles.Info}>
                    <Text  code>BONO FIDELIZACION</Text>
                    <Select 
                        className={styles.Cuadros}
                        onChange={(e)=>handleChangeCombos(e,'BonoFidelizacion')}
                        placeholder="SELECCIONE"
                        disabled={ValidarPrepago}
                        value={state.BonoFidelizacion}
                        size={'default'}
                    >
                        { LISTAR_BONO_FIDELIZACION }
                    </Select>  
                </div>

                <div className={styles.Info}>
                    <Text  code>RUC</Text>
                    <Select 
                        className={styles.Cuadros}
                        onChange={(e)=>handleChangeCombos(e,'Ruc')}
                        placeholder="SELECCIONE"
                        // disabled={ValidarPrepago}
                        value={state.Ruc}
                        size={'default'}
                    >
                        { LISTAR_RUC }
                    </Select>  
                </div>

                <div className={styles.Info}>
                    <Text code>CUPO</Text>
                    <Input 
                        className={styles.Cuadros}
                        name="Cupo" 
                        onChange={handleChangeInputCupo} 
                        value={state.Cupo}
                    />
                </div>

                <div className={styles.Info}>

                    <Button 
                        className={styles.Boton} 
                        type="primary"  
                        onClick={()=>Guardar()}
                    >Guardar
                    </Button> 
                    
                </div>

                <Modal title={<div style={{fontSize:20}}><InfoCircleOutlined style={{ color: 'green'}}/></div>} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <div style={{display:'flex', flexDirection:'row'}}>
                        <UserOutlined />
                        <p style={{fontSize:11,marginLeft:'5px'}}><span><b>IDENTIFICACIÓN: </b></span>{stateInfo.Indetificacion}</p>
                    </div>
                    <div style={{display:'flex', flexDirection:'row'}}>
                        <SmileOutlined />
                        <p style={{fontSize:11,marginLeft:'5px'}}><span><b>NOMBRE: </b></span>{stateInfo.Nombre}</p>
                    </div>

                    <div style={{display:'flex', flexDirection:'row'}}>
                        <PushpinOutlined />
                        <p style={{fontSize:11,marginLeft:'5px'}}><span><b>CIUDAD: </b></span>{stateInfo.Ciudad}</p>
                    </div>
                </Modal>
            </div>
            {/* <div><i className={state.Font} style={{fontSize:'20px', color:'black'}}></i></div> */}
        </>
    )
}

export default DashboardPage

const useStyles = makeStyles(() => ({
    Imagen:{
        textAlign:'right',
        margin:'20px',
    },
    BtnIngresar:{
        margin:'12px',
        width:'200px',
        "@media (max-width: 900px)": {
            width:'95%',
        }
    },
    BtnGuardar:{
        margin:'12px',
        width:'200px',
    },
    Componentes:{
        width:'90%',
        textAlign:'center'
    },
    Contenedor:{
        textAlign:'center',
    },
    Info:{
        padding:'12px',
        width:'14%',
        float:'left',
        "@media (max-width: 900px)": {
            width:'100%',
            textAlign:'left'
        }
    },
    InfoTelefono:{
        padding:'10px',
        width:'14%',
        float:'left',
        "@media (max-width: 900px)": {
            width:'100%',
            textAlign:'left'
        }
    },
    Cuadros:{
        marginTop:'5px',
        width:'100%',
        textAlign:'center',
    },
    Boton:{
        width:'100%',
        marginTop:'25px'
    }
}));