import { createContext, useState , useEffect} from "react";
import {ObtenerPermisos} from '../Api/ObtenerPermisos';
export const AuthContext = createContext();

const AuthProvider = ({children}) =>{
    const [user , setUser] =  useState(
        JSON.parse(localStorage.getItem("User"))  || null
    );

    const  [permisos , setPermisos] =  useState([]);


    useEffect(async() => {
        try{
            localStorage.setItem("User",JSON.stringify(user))
            const Permisos = await ObtenerPermisos(user.Rol);
            if(Permisos.length>0){
                setPermisos(Permisos)
            }
            
        }catch(error){
            localStorage.removeItem("User");
        }
    }, [user])

    const contextValue = {
        user,
        login(User){
            setUser(User)
        },
        logout(){
            setUser(null)
        },
        isLogged(){
            return !! user;
        },
        isAdmin(){
            if(user.Rol ==="ADMINISTRADOR"){
                return true;
            }
            return false;
        },
        NavBar(){
            return permisos && permisos
        },
        Permisos(){
            return permisos
        },
        Validacion(rest){
            let Validador = false;
            if(user){
                Validador = permisos.filter( Nav => {
                    const result = rest.path === Nav.Ruta;
                    return result;
                } );
            }
            if(Validador.length > 0 ){
                Validador = true
            }
            return Validador;
        }
    }
    return <AuthContext.Provider value={contextValue}>
        {children}
    </AuthContext.Provider>
}

export default AuthProvider ; 