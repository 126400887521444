async function GetDescargas(FechaInicial,FechaFinal,Validacion,Todo){
    const UrlApi ='https://validacion.ventasvirtuales.com.ec/ApiValidacion/procedures/get_methods/exportar/getDescargas.php?Token=015ec0d618fe3fa9c7d0a1abda2b159c&FechaInicial='+FechaInicial+'&FechaFinal='+FechaFinal+'&Validacion='+Validacion+'&Todo='+Todo;
        try{
            let response= await fetch(UrlApi);
            let responseJson= await response.json();
            let res= await responseJson.body;
            return res;
        }catch(error){
            console.error(`ERROR DESCARGAS ES :  ${error}`)
        }
}
export  {GetDescargas};