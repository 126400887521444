import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import { 
    Image 
} from 'antd';
const HomePage = () => {
    const styles= useStyles();
    return (
        <>
            <div className={styles.Imagen}>
                <Image
                    width={'20%'}
                    src="https://validacion.ventasvirtuales.com.ec/Ws/img/ActuaLead.png"
                />
            </div>
        </>
    )
}

export default HomePage


const useStyles = makeStyles((theme) => ({
    Imagen:{
        textAlign:'center',
        marginTop:'150px'
    }
}));
