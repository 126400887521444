import React from 'react'
import { BrowserRouter as Router , Route , Switch } from 'react-router-dom'
import Navbar from '../Components/Navbar'
// import AboutPage from '../Pages/AboutPage'
// import ContactPage from '../Pages/ContactPage'
import DashboardPage from '../Pages/DashboardPage'
import Error404 from '../Pages/Error404'
import AccesoDenegado from '../Pages/AccesoDenegado'
import HomePage from '../Pages/HomePage'
import LoginPage from '../Pages/LoginPage'
import PymentsPage from '../Pages/PymentsPage'
import UserPage from '../Pages/UserPage'
import RolesPage from '../Pages/RolesPage'
import PermisosPage from '../Pages/PermisosPage'
import ReportePage from '../Pages/ReportePage'
// import PivateRoute from './PivateRoute'
import PublicRoute from './PublicRoute'
import AdminRoute from './AdminRoute'

const AppRoute = () => {
    return (
        <Router>
                <Navbar/>
            <Switch>

                {/* RUTAS COMUNES */}

                {/* <Route exact path="/about" component={AboutPage}/>
                <Route exact  path="/contact" component={ContactPage}/> */}

                {/* RUTAS PUBLICAS */}

                <PublicRoute exact  path="/login" component={LoginPage}/>
                

                {/* RUTAS PRIVADAS */}

                <AdminRoute exact  path="/dashboard" component={DashboardPage}/>
                <AdminRoute exact  path="/pyments" component={PymentsPage}/>
                <AdminRoute exact  path="/usuarios" component={UserPage}/>
                <AdminRoute exact  path="/roles" component={RolesPage}/>
                <AdminRoute exact  path="/permisos" component={PermisosPage}/>
                <AdminRoute exact  path="/reporte" component={ReportePage}/>

                {/* RUTAS POR DEFECTO */}
                <Route exact path="/AccesoDenegado" component={AccesoDenegado}/>
                <Route exact path="/" component={HomePage}/> 
                <Route exact path="*" component={Error404}/>

            </Switch>
        </Router>
    )
}

export default AppRoute
